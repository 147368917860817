<template>
  <div class="page-header">
    <div class="df-flex-l df-flex-col-t df-flex-col-m header__content">
      <div class="df-flex-l justify-space-between align-center">
        <slot name="title-section">
          <df-header-title :title="title" :subtitle="subtitle" />
        </slot>
        <slot name="infos"></slot>
      </div>
      <div v-if="$slots.actions" class="df-flex-l align-center header__actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import DfHeaderTitle from '@/lib/components/Header/DfHeaderTitle.vue'

export default {
  name: 'DfPageHeader',

  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },

  components: {
    DfHeaderTitle,
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  padding: 40px 16px 16px 16px;
  width: auto;

  @include d(m) {
    padding: 24px 8px 12px 8px;
  }
  .header__content {
    max-width: 1440px;
    margin: 0px auto;

    .header__actions {
      flex: 0 0 auto;
    }
  }
}
</style>
